<template>
  <div class="app-container">
    <div v-scrollTop>
      <formPart v-if="childPermission.search" :query="query" :sup_this="sup_this" :inDispatch="true"></formPart>
      <div class="flex-middle" v-if="childPermission.cutOrder">
        <checkAllData
          ref="checkAllData"
          :tableData="data"
          :page="page"
          :size="size"
          :total="total"
          :isPageChange="isPageChange"
          :setIsPageChangeFalse="setIsPageChangeFalse"
          :getAllData="getAllData"
          :selectionData="factoryData"
          :getElTableInstance="getElTableInstance"
          @checkAllStatus="checkAllStatusChange"
        ></checkAllData>
        <loadingBtn type="primary" size="small" class="ml20" @click="handleCutoffOrder">截单</loadingBtn>
      </div>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      <tablePart
        ref="table"
        :sup_this="sup_this"
        :tableLoading="tableLoading"
        :orderListData="data"
        :orderStatus="orderStatus"
        :permission="childPermission"
        @takeFactory="takeFactory"
      ></tablePart>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </div>
  </div>
</template>

<script>
import formPart from '../../order/module/orderListModule/formPart'
import sendFactory from '../../order/module/orderListModule/sendFactory'
import tablePart from '../../order/module/orderListModule/tablePart'
import pagination from '../../order/module/orderListModule/pagination'
import checkAllData from '@/views/components/checkAllData3'
import { initDataMixin } from '@/mixins'
import { getValueFromObj, checkPermission } from '@/utils'
import { IN_DISPATCH } from '@/utils/constant'
import { initData } from '@/api/data'
import { cutOrder } from '@/api/order/orderListData'
import { mapGetters } from 'vuex'
import { find } from 'lodash'

let childPermission = {
  search: checkPermission('externaladmin:orderManage:inDispatch:search'),
  cutOrder: checkPermission('externaladmin:orderManage:inDispatch:cutOrder'),
  update: false, // checkPermission('externaladmin:orderManage:inDispatch:update'),
  edit: false, // checkPermission('externaladmin:orderManage:inDispatch:edit'),
  detail: checkPermission('externaladmin:orderManage:inDispatch:detail')
}
export default {
  name: 'orderInDispatch',
  mixins: [initDataMixin],
  components: {
    formPart,
    sendFactory,
    tablePart,
    pagination,
    checkAllData
  },
  data() {
    return {
      childPermission,
      sup_this: this,
      url: '/externaladmin/orderService/externalOrder/orderList',
      method: 'post',
      mergeData: {
        isReturnRelatedData: 1,
        isGetAllOrder: 1
      },
      query: {
        orderStatus: IN_DISPATCH,
        orderItems: [
          {
            asc: false,
            column: 'order_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ]
      },
      orderStatus: IN_DISPATCH,

      // 全选必加
      factoryData: [],
      selectionDataAllArr: []
    }
  },
  computed: {
    ...mapGetters(['factoryCategory']),
    handleSearchFormProps() {
      return {
        factoryCategoryId: (value, query) => {
          let cItem = find(this.factoryCategory, { value })
          if (cItem) {
            return {
              $isMergeData: true,
              styleIds: cItem.children.map(({ value }) => value)
            }
          }
        }
      }
    }
  },
  methods: {
    async handleCutoffOrder() {
      let selectionDataAllArr = await this.getSelectionDataAllArr()
      if (!selectionDataAllArr) return
      if (selectionDataAllArr.length === 0) return this.$message.warning('请先选择订单')

      let confirm = await awaitFormResolve(
        this.$confirm('确认是否截单?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      )
      if (!confirm) return this.$message.info('取消截单')

      let res = await awaitResolve(
        cutOrder({
          idList: selectionDataAllArr.map(({ id }) => id)
        })
      )
      if (!res) return this.$message.warning('截单失败')

      this.$message.success('截单成功')
      await this.init()
    },
    takeFactory(data) {
      this.factoryData = data
      // console.log(this.factoryData);
    },

    // 全选必加
    initCallBack(res, postData) {
      let initCallBack = getValueFromObj(this.$refs.checkAllData, 'initCallBack')
      if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
    },
    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
      // console.log(this.selectionDataAllArr)
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.checkAllData.getSelectionDataAllArr()
      this.selectionDataAllArr = tempArr
      // console.log(tempArr)
      return tempArr
    },
    async getAllData() {
      let res = await awaitResolve(
        initData(this.url, this.method, {
          ...this.params,
          isReturnRelatedData: 0,
          isReturnWaybillChangeRecord: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res
    },
    getElTableInstance() {
      return getValueFromObj(this.$refs, 'table.$refs.table') || {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
